
@font-face {
  font-family: 'Lexend';
  src: local('Lexend'), url(./css/fonts/Lexend-Light.ttf) format('truetype');
}

@font-face {

  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-variation-settings: 'wght' 100..700;

  src: url('./css/fonts/material-symbols-outlined.woff2') format('woff2-variations');

}

body {

  height: 100vh;
  width: 100vw;

  margin: 0;
  font-family: Lexend;
  font-weight: 100;
  font-style: normal;
}

#root{
  height: inherit;
  width: inherit;
}